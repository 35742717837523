<template>
<div>
    <VestInitSum />
</div>
</template>

<script>
import VestInitSum from '../../components/dashboard/vestfolder/VestInitSummary.vue'
export default {
    components: {
        VestInitSum
    }
}
</script>

