<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
            <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>
        <!-- <input type="text" v-model="filters.name.value"> -->
        <div class="pt-4">
          <div class="row">
            <div class="col-md-3 col-1" v-if="windowWidth > 767">
           <button
                class="pl-1"
                style="background:#fff; border-radius: 5px; min-height: 35px; border: 1px solid #FAF0F0;"
                @click="sentToAmountView"
              >
              <img src="../../../assets/images/left_arrow.png" class="mb-1" height="12"> Back
              </button>
            </div>
            <div class="col-md-6 col-12 text-center">
              <div style="min-width:100%; display:flex; justify-content:center">
                <div
                  class="tab-div"
                  style="display:flex; justify-content:center;  align-items:center; max-width:400px; min-height:60px"
                >
                  <div
                    style="flex-basis:50%;  border-bottom:1px solid #0094FF"
                    class="pb-2 point-cursor"
                  >
                    <p>Vestlock</p>
                  </div>
                  <div style="flex-basis:50%" class="pb-2 point-cursor">
                    <p @click="gotoVestPortfolio">Vestlock Portfolio</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-1 text-right" v-if="windowWidth > 767">
             <button
                class=" pl-3 mr-3"
                style="background:#fff; border-radius: 5px; min-height: 35px; border: 1px solid #FAF0F0;"   @click="cancelVestLock"
              > Cancel
                <img src="../../../assets/images/x.png" class="mb-1 pr-1" height="10">
              </button>
            </div>
          </div>
        </div>

        <div class="container" v-if="!preLoader">
          <div style="margin:0 auto;max-width:450px; ">
            <div style="max-width:450px; display:flex;padding-top:60px">
              <div style="flex:1" class="pb-4">
                <p>
                  <b>You will be investing
                    <span>&#8358;</span>
                    {{formatNumber(vestLockData.amountInvested)}} in ;
                  </b>
                </p>
              </div>
              <div style="flex:1" class="pb-4 text-right">
                <p class="underline" @click="sentToAmountView"> Edit amount </p>
              </div>
            </div>
          </div>

          <div style=" max-width:450px; margin:0 auto">
            <div style="background:#fff;border-radius:10px; padding:20px; border: 1px solid #0094FF">

              <h6 class="mb-4">
                <b>{{vestLockData.name}}</b>
              </h6>

              <div>
                <p>
                  <i class="fa fa-circle pr-2" style="color:#828282"></i>
                  {{vestLockData.calcRoi}} % upfront interest
                </p>
                <h5>
                  <i class="fa fa-circle pr-2" style="color:#fff"></i>
                  <span>&#8358;</span>
                  {{formatNumber(vestLockData.calcInterest)}}
                </h5>
              </div>

              <div class="mt-4">
                <h5 style="color:#4F4F4F">
                  <i class="fa fa-circle pr-2" style="color:#828282; font-size:14px"></i>
                  {{vestLockData.fixed_tenor}} Month duration
                </h5>
              </div>

              <div class="mt-4">
                <div class="row">
                  <div class="col-md-6">
                    <p style="color:#828282;">Provided by :</p>
                    <h6>
                      <b>{{vestLockData.atp}}</b>
                    </h6>
                  </div>
                  <div class="col-md-6 text-right">
                    <img :src="imgUrlFromServer+vestLockData.atp_image" height="40" class="mb-2">
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <button class="vest-btn-2 mt-3" style="min-width:100%" @click="sendToSummary">
                <b>Continue</b>
              </button>
            </div>
          </div>
          <div class="display-div mb-4" v-if="windowWidth < 767" style="max-width: 450px; min-width:50%; margin:0 auto">
            <div>
              <button
                class="mt-4 pl-1"
                style="background:#fff; border-radius: 5px; min-height: 35px; border: 1px solid #FAF0F0;"
                @click="sentToAmountView"
              >
              <img src="../../../assets/images/left_arrow.png" class="mb-1" height="12"> Back
              </button>
            </div>
            <div class="text-right">
              <button
                class="mt-4 pl-2"
                style="background:#fff; border-radius: 5px; min-height: 35px; border: 1px solid #FAF0F0;"   @click="cancelVestLock"
              > Cancel
                <img src="../../../assets/images/x.png" class="mb-1 pr-1" height="10">
              </button>
            </div>
          </div>
        </div>

        <!-- <div class="pt-4">
          <div class="row">
            <div class="col-md-3 col-5">
              <img src="../../../assets/images/back2.png" style="cursor:pointer" @click="sentToAmountView">
            </div>
            <div class="col-md-6  col-1 text-center">
          
            </div>
            <div class="col-md-3 col-5 text-right">
              <img
                src="../../../assets/images/cancel2.png"
                style="cursor:pointer; padding-right:30px"
              @click="cancelVestLock">
            </div>
          </div>
        </div>-->
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {}
    };
  },
  methods: {
    sendToMarketPlace() {
      this.$router.push("/market");
    },

    sentToAmountView() {
      this.$router.push("/vest/amount");
      this.$store.commit("auth/saveVestRouteVisited", "/vest/amount");
    },
    gotoVestPortfolio() {
      this.$router.push("/vest/portfolio");
      this.$store.commit("auth/saveVestRouteVisited", "/vest/portfolio");
    },
    sendToSummary() {
      this.$router.push("/vest/summary");
       let userAmount = localStorage.getItem("investAmount")
        let apiData = {
 
                  amount:userAmount,
                   type:'fullsum'
         }
 this.$store.dispatch("market/getVestLockPortfolio", apiData)

    },

    cancelVestLock() {
      swal({
        title: this.tran.transactions,
        text: this.tran.end_vestLock,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: this.tran.continue,
        confirmButtonText: this.tran.yes_cancel,
        animation: false,
        customClass: "animated fadeIn"
      }).then(result => {
        if (result.value) {
          this.$router.push("/vest/home");
        }
      });
    }
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
      vestLockData: state => state.market.vestLockData
    }),

    returnAmountOfPenalty() {
      return this.penaltyAmount;
    }
  },
  mounted() {
    let heading = {
      main: this.tran.vest_lock
    };
    this.$store.commit(
      "auth/saveVestRouteVisitedAfterClickingPortfolio",
      this.$route.path
    );
    this.$store.commit("market/savePageHeading", heading);
  },

  beforeCreate() {

     let userAmount = localStorage.getItem("investAmount")

if(userAmount == 'false'){

  this.$router.push('/vest/amount')
}
else{

  let apiData = {
 
                  amount:userAmount,
                   type:'initsum'
         }
 this.$store.dispatch("market/getVestLockPortfolio", apiData)

}


    this.$store.dispatch("market/getPortfolio");
    this.$store.commit("market/checkSearchObject", "portfolio");
  },

  beforeDestroy() {
    this.$store.commit("market/startPreloader");
  }
};
</script>

<style scoped>
.fade-elem {
  background: #fff;
  border: 2px solid #e0e0e0;
}
</style>
